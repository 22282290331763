/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import interceptorSetup from './interceptors';
import * as tracking from './tracking';

if (process.env.VUE_APP_API_TARGET === 'mock') {
  console.log('TCL: services index VUE_APP_API_TARGET', process.env.VUE_APP_API_TARGET);
  // eslint-disable-next-line global-require
  require(/* webpackChunkName: "mock-services" */ '../mock-services'); // import mock adapter if env is mock
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
interceptorSetup();

export {
  tracking,
};
