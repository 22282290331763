export default () => {
  switch (process.env.VUE_APP_API_TARGET) {
    case 'mock':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BM1gq5M_Olbhthlmppg0V8shfH69DGEgyMolLVqAvY_oqY_Qsi9ainYKysEpdeyAzbPdMC3gn1vYPHnjAeFqSII',
        fcmSenderId: '205952350480',
      };
    case 'sandbox':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BM1gq5M_Olbhthlmppg0V8shfH69DGEgyMolLVqAvY_oqY_Qsi9ainYKysEpdeyAzbPdMC3gn1vYPHnjAeFqSII',
        fcmSenderId: '205952350480',
      };
    case 'staging':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BM1gq5M_Olbhthlmppg0V8shfH69DGEgyMolLVqAvY_oqY_Qsi9ainYKysEpdeyAzbPdMC3gn1vYPHnjAeFqSII',
        fcmSenderId: '205952350480',
      };
    case 'production':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BM1gq5M_Olbhthlmppg0V8shfH69DGEgyMolLVqAvY_oqY_Qsi9ainYKysEpdeyAzbPdMC3gn1vYPHnjAeFqSII',
        fcmSenderId: '205952350480',
      };
    default:
      break;
  }
};
