import Vue from 'vue';
import VuetifyDialog from 'vuetify-dialog';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import * as strings from './assets/strings';
import './styles/global.scss';
import * as services from './providers/services';
import keys from '../keys';
import CommonError from './components/Common/CommonError.vue';

import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.prototype.$_strings = strings;
Vue.prototype.$_services = services;

Vue.component('CommonError', CommonError);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
Vue.use(VueGoogleMaps, {
  load: {
    key: keys().googleMapsApiKey,
    libraries: 'places',
    region: 'id',
    language: 'id',
  },
});
Vue.config.productionTip = false;

if (process.env.VUE_APP_API_TARGET === 'production') {
  console.log = () => { };
}

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
