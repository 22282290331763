export default {
  errors: {
    COMMON: 'Terjadi Kesalahan',
    NOT_FOUND: 'Nomor Pesanan / Kiriman Tidak Ditemukan',
    NO_INTERNET: 'Tidak ada koneksi internet',
    EMAIL_NOT_REGISTERED: 'Email belum terdaftar, silahkan hubungi admin',
    EMAIL_ALREADY_USED: 'Email sudah digunakan, silahkan gunakan yang lain',
    NO_ACCESS: 'Anda tidak punya akses untuk membuka halaman ini',
  },
  validation: {
    EMAIL: 'Format email yang digunakan salah',
    PASSWORD: 'Silahkan masukkan password anda',
    PASSWORD_AGAIN: 'Silahkan masukkan kembali kata sandi anda',
    PASSWORD_NOT_MATCH: 'Kata sandi tidak cocok!',
    PASSWORD_NOT_SIX: 'Panjang kata sandi harus minimal 6 karakter',
    USERNAME: 'Silahkan masukkan nama Anda',
    REQUIRED: (field) => `${field} wajib diisi`,
    EMPTY: 'Beberapa kolom harus diisi',
  },
};
