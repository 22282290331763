import axios from 'axios';
import urls from './urls';

const fetchOrder = async (orderNo) => {
  try {
    const res = await axios.get(`${urls.trackingOrder}/${orderNo}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchOrder2 = async (orderNo) => {
  try {
    const res = await axios.get(
      `${urls.trackingOrder}/${orderNo}`,
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchOrder,
  fetchOrder2,
};
