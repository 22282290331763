/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { VAlert } from 'vuetify/lib';
import Vue from 'vue';
import * as Strings from '../../assets/strings';

export default function setup() {
  axios.interceptors.request.use((config) => config, (err) => Promise.reject(err));
  axios.interceptors.response.use((response) => response,
  // intercept the global error
    (error) => {
      const originalRequest = error.config;
      const urlLastIndex = error.config.url.substr(error.config.url.lastIndexOf('/') + 1);
      if (error.response) {
        if (error.response.status === 404) {
          Vue.prototype.$dialog.notify.error(Strings.messages.errors.NOT_FOUND, { timeout: 5000 });
        } else if (error.response.status === 401) {
          originalRequest._retry = true;
        } else if (error.response.status === 500) {
          return VAlert.prototype.$dialog.notify.error(Strings.messages.errors.INTERNAL_SERVER_ERROR, { timeout: 5000 });
        } else if (error.response.data) {
          VAlert.prototype.$dialog.notify.error(error.response.data.message, { timeout: 5000 });
        }
      } else if (error.request) {
        Vue.prototype.$dialog.notify.error(Strings.messages.errors.NO_INTERNET, { timeout: 5000 });
      } else {
        Vue.prototype.$dialog.notify.error(Strings.messages.errors.COMMON, { timeout: 5000 });
      }
      return Promise.reject(error);
    });
}
