<template>
  <v-app>
    <router-view></router-view>
    <loading ref="loading" />
  </v-app>
</template>

<script>
import loading from './components/Common/loading';

export default {
  name: 'App',

  components: {
    loading,
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$root.$loading = this.$refs.loading;
  },
};
</script>

<style lang="sass">
</style>
