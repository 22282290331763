import Vue from 'vue';
import VueRouter from 'vue-router';

const PageHome = () => import(/* webpackChunkName: "view-Welcome" */ '../views/Home.vue');
const Home = () => import(/* webpackChunkName: "component-WelcomeLogin" */ '../components/Home');
const Detail = () => import(/* webpackChunkName: "component-detail" */ '../components/Home/Detail.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/main',
    component: PageHome,
    children: [
      {
        path: '',
        redirect: '/main/home',
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: {
          title: 'E-logistic Web Tracking',
        },
      },
      {
        path: 'order-detail/:orderNo',
        name: 'order-detail',
        component: Detail,
        meta: {
          title: 'E-logistic Web Tracking',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/main/home',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || 'E-logistic Web Tracking';
  next();
});

export default router;
