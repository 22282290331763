const ORDER_NO = 'No Kiriman';
const DELIVERY_DATE = 'Tanggal Pengiriman';
const CREATE_DATE = 'Tanggal Buat';
const ORIGIN = 'Lokasi Asal';
const DESTINATION = 'Lokasi Tujuan Akhir';
const ETA = 'ETA';
const DESC_TARIF = 'Ket Tarif';
const ORDER_NUMBER = 'No Pesanan';
const SERVICE_TYPE = 'Jenis Tarif';
const CUSTOMER_NAME = 'Nama Customer';
const MULTIPICK = 'Multipick';
const MULTIDROP = 'Multidrop';
const NO_GRUP_CARGO = 'No Grup Kargo';
const NO_CARGO = 'No Kargo';
const TRANSPORTER = '3PL';
const TRANSPORTER_NAME = 'Nama 3PL';
const DELIVERY_DATE_ORIGIN = 'Tgl Pengiriman';
const DELIVERY_DATE_DESTINATION = 'Tgl Berangkat';
const NO_AVIATION_SERVICE = 'No. Pelayanan Penerbangan';
const SHIPPER = 'Shipper';
const SHIPPER_INFO = 'Informasi Shipper';
const TRIP_INFO = 'Informasi Perjalanan';
const RUTE_INFO = 'Informasi Rute';
const VEHICLE_INFO = 'Informasi Kendaraan';
const MULTIMODA_INFO = 'Informasi Multi Moda';
const VEHICLE = 'Kendaraan';
const VEHICLE_NUMBER = 'No. Kendaraan';
const LABEL3PL = 'Rekanan 3PL';
const LAST_LOCATION = 'Lokasi Terakhir';
const STATUS = 'Status';
const SEARCHNO = 'Cari No. Kiriman/Pesanan/Cargo';
const FILTER = 'Filter Berdasarkan';
const PAGE_NAME = 'Daftar Pesanan Hari Ini';
const MERGE = 'Gabungkan';
const HAVE_NOT_BEEN_ASSIGNED = 'Belum diassign satupun';
const PARTIALLY_ASSIGNED = 'Ada sebagian yang belum diassign';
const ASSIGN1 = 'Assign / Ubah 3PL';
const CHANGE_RUTE = 'Ubah Rute';
const ADD_RUTE = 'Tambah Rute';
const SEND_DATE = 'Tanggal Pengiriman';
const TYPE_VEHICLE = 'Tipe Kendaraan';
const PICK_UP_LOCATION = 'Lokasi Penjemputan';
const LOCATION_IN_ACCEPTED = 'Lokasi Bongkar';
const MERGE_ORDER_LCL = 'Gabungkan Pesanan LCL';
const COMBINED_ORDERS = 'Pesanan yang sudah digabungkan';
const NOT_COMBINED_ORDERS = 'Pesanan belum digabungkan';
const TONASE = 'Tonase';
const KUBIKASI = 'Kubikasi';
const FILTER_PROVINSI_ORIGIN = 'Filter Provinsi Asal';
const FILTER_PROVINSI_DESTINATION = 'Filter Provinsi Tujuan';
const SELECT = 'Pilih';
const CANCEL_ORDER = 'Batalkan Pesanan';
const ACTION = 'Aksi';
const ASSIGN_MULTI_MODA = 'Assign Rekanan 3PL - Multi Moda';
const ASSIGN_3PL = 'Assign Rekanan 3PL';
const PLEASE_SELECT = 'Silahkan pilih terlebih dahulu';
const UPDATE_STATUS = 'Update Status';
const CHANGE_TRANSPORTER = 'Ubah Rekanan 3PL & Data Multi Moda';
const ALERT_DELETE_MULTIMODA = 'Apakah anda yakin ingin membatalkan Kiriman ini ?';
const NOTE = 'Catatan';
const STATUS_NOW = 'Status Saat Ini';
const NEW_STATUS = 'Status Baru';
const BACKHAULING = 'Backhauling';
const ACCEPT_ORDER = 'Terima Pesanan';
const REJECT_ORDER = 'Tolak Pesanan';
const LIST_COST = 'List biaya ongkos kirim';
const UPDATE_INFORMATION = 'Ubah / Update Informasi';
const TYPE_MODA = 'Tipe Moda';
const LUMPSUM = 'Lumpsum';
const CRUISE_NUMBER = 'No.Pelayaran Penerbangan';
const RITASE = 'Ritase';
const KILO = 'Kilo';
const ONCALL = 'Oncall';
const BOX = 'Box';
const QUANTITY_INFO = 'Informasi Quantity';
const DRIVER_NAME = 'Nama Sopir';
const CAR_TYPE = 'Tipe Mobil';
const NO_TRANSPORTER = 'No Kendaraan';
const CONTACT_DRIVER = 'Kontak Sopir';
const FILTER_PROVINCE = 'Filter Provinsi';
const TARIF_INFO = 'Keterangan Tarif';
const SHIPPER_NAME = 'Nama Shipper';
const NAME_SENDER = 'Nama Pengirim';
const CATEGORY = 'Kategory';
const SUB_CATEGORY = 'Sub Kategori';
const PHONE_NUMBER_SENDER = 'No Telepon Pengirim';
const LABEL_STATUS_ORDER = 'Status Pesanan';
const DOCUMENTATION = 'Dokumentasi';
const COMPLETED_DOCUMENT = 'Dokumen Lengkap';
const FOTO_LATTER = 'Foto Surat Jalan';
const ADD_DOCUMENT_FOTO = 'Tambah Foto Dokumen';
const LOCATION_MAP = 'Peta Lokasi Asal dan Tujuan Pesanan';
const COMPLATE_THE_ORDER = 'Selesaikan Pesanan';
const COMPLATE_THE_CARGO = 'Selesaikan Kargo';
const CANCEL_THE_ORDER = 'Batalkan Pesanan';
const CONFIRMED = 'Terkonfirmasi';
const CARGO_STATUS = 'Status Kargo';
const CALCULATE_COST = 'Hitung Biaya';
const MAIN_COST = 'Biaya Utama (Rp)';
const ADDITIONAL_COST = 'Biaya Tambahan (Rp)';
const AMOUNT_OF_COSTS = 'Jumlah Biaya (Rp)';
const TOTAL_COST = 'Total Biaya (Rp)';
const TOTAL_BILL = 'Total Tagihan (Rp)';
const GOODS_RECEIVED_WAREHOUSE = 'Barang Diterima Gudang';
const CALCULATE = 'Hitung';
const CHANGE_QUANTITY_ORDER = 'Mengubah quantity untuk order';
const NO_ROAD_LETTER = 'No Surat Jalan';
const QUANTITY = 'Quantity';
const UNIT = 'Unit';
const COMPLATED_THE_ORDER = 'Menyelesaikan Pesanan';
const LABEL_CANCEL_ORDER = 'Apakah anda yakin ingin membatalkan Pesanan ini?';
const LABEL_DELETE_RUTE = 'Apakah anda yakin untuk menghapus rute?';
const OVERWRITE_CONFIRMATION_SHIPPER = 'Overwrite konfirmasi Shipper';
const LABEL_GOODS_RECEIVED_WAREHOUSE = 'Apakah anda yakin barang diterima Gudang?';
const UPLOAD_FOTO_DOCUMENTATION = 'Unggah Foto Dokumentasi';
const DOCUMENT_TYPE = 'Tipe Dokumen';
const DOCUMENT_PHOTOS = 'Dokumen Photo';
const MASSA = 'Tonase(kg)';
const CUBIC = 'Kubikasi(m3)';
const BOX_CARTON = 'Karton(ktn)';
const TONNAGE = 'Tonase';
const STATUS_ORDER = {
  CANCEL: 'Dibatalkan',
  CONFIRM: 'Terkonfirmasi',
};

export default {
  ORDER_NO,
  VEHICLE,
  VEHICLE_NUMBER,
  LABEL3PL,
  LAST_LOCATION,
  STATUS,
  SEARCHNO,
  FILTER,
  PAGE_NAME,
  DELIVERY_DATE,
  CREATE_DATE,
  ORIGIN,
  DESTINATION,
  ETA,
  DESC_TARIF,
  ORDER_NUMBER,
  SERVICE_TYPE,
  CUSTOMER_NAME,
  MULTIPICK,
  MULTIDROP,
  NO_GRUP_CARGO,
  NO_CARGO,
  TRANSPORTER,
  DELIVERY_DATE_ORIGIN,
  DELIVERY_DATE_DESTINATION,
  NO_AVIATION_SERVICE,
  SHIPPER_INFO,
  TRIP_INFO,
  RUTE_INFO,
  VEHICLE_INFO,
  MULTIMODA_INFO,
  SHIPPER,
  MERGE,
  HAVE_NOT_BEEN_ASSIGNED,
  PARTIALLY_ASSIGNED,
  ASSIGN1,
  CHANGE_RUTE,
  ADD_RUTE,
  SEND_DATE,
  TYPE_VEHICLE,
  PICK_UP_LOCATION,
  STATUS_ORDER,
  LOCATION_IN_ACCEPTED,
  MERGE_ORDER_LCL,
  COMBINED_ORDERS,
  TONASE,
  KUBIKASI,
  FILTER_PROVINSI_ORIGIN,
  FILTER_PROVINSI_DESTINATION,
  SELECT,
  NOT_COMBINED_ORDERS,
  CANCEL_ORDER,
  ACTION,
  ASSIGN_MULTI_MODA,
  ASSIGN_3PL,
  PLEASE_SELECT,
  UPDATE_STATUS,
  CHANGE_TRANSPORTER,
  ALERT_DELETE_MULTIMODA,
  NOTE,
  STATUS_NOW,
  NEW_STATUS,
  BACKHAULING,
  ACCEPT_ORDER,
  REJECT_ORDER,
  LIST_COST,
  UPDATE_INFORMATION,
  TYPE_MODA,
  LUMPSUM,
  CRUISE_NUMBER,
  RITASE,
  KILO,
  ONCALL,
  BOX,
  QUANTITY_INFO,
  DRIVER_NAME,
  CAR_TYPE,
  NO_TRANSPORTER,
  CONTACT_DRIVER,
  FILTER_PROVINCE,
  TARIF_INFO,
  SHIPPER_NAME,
  NAME_SENDER,
  CATEGORY,
  SUB_CATEGORY,
  PHONE_NUMBER_SENDER,
  LABEL_STATUS_ORDER,
  DOCUMENTATION,
  COMPLETED_DOCUMENT,
  FOTO_LATTER,
  ADD_DOCUMENT_FOTO,
  LOCATION_MAP,
  COMPLATE_THE_ORDER,
  COMPLATE_THE_CARGO,
  CANCEL_THE_ORDER,
  CONFIRMED,
  TRANSPORTER_NAME,
  CARGO_STATUS,
  CALCULATE_COST,
  MAIN_COST,
  ADDITIONAL_COST,
  AMOUNT_OF_COSTS,
  TOTAL_COST,
  TOTAL_BILL,
  GOODS_RECEIVED_WAREHOUSE,
  CALCULATE,
  CHANGE_QUANTITY_ORDER,
  NO_ROAD_LETTER,
  QUANTITY,
  UNIT,
  COMPLATED_THE_ORDER,
  LABEL_CANCEL_ORDER,
  LABEL_DELETE_RUTE,
  OVERWRITE_CONFIRMATION_SHIPPER,
  LABEL_GOODS_RECEIVED_WAREHOUSE,
  UPLOAD_FOTO_DOCUMENTATION,
  DOCUMENT_TYPE,
  DOCUMENT_PHOTOS,
  MASSA,
  CUBIC,
  BOX_CARTON,
  TONNAGE,
};
