export default {
  EMAIL: 'Email',
  EMAIL_HINT: 'Contoh: email@fastlog.id',
  PASSWORD: 'Kata Sandi',
  REPEAT_PASSWORD: 'Ulang Kata Sandi',
  LOGIN: 'Masuk',
  ACTIVATE_ACCOUNT: 'Aktivasi Akun',
  USERNAME: 'Nama Lengkap',
  PLACEHOLDER_ORDER_NO: 'Masukkan Nomor Pesanan/Kiriman',
  TRACK: 'LACAK',
};
